.submitBtn{
    background: #37BC83;
    color: white;
    font-weight: bold;
    height: 3rem;
    border-radius: 5px;
    padding: 0px 30px 0px 30px;
    /* margin-top: 10px; */
    position: relative;
    top: 30px;
}

.centerContent{
    margin:auto;
  
}
.contactForm input[type=text]{
    border-right-width: 0 !important;
    border-left-width: 0 !important;
    border-top-width: 0 !important;
    padding: 1%!important;
    border-bottom-width: 2px!important;
}

.contactForm textarea{
    border-right-width: 0 !important;
    border-left-width: 0 !important;
    border-top-width: 0 !important;
    padding: 1%!important;
    border-bottom-width: 2px!important;

}


.btnMargin{
    margin-top:10px;
}
.marginAuto{
    margin:auto
}
.paddingRow{
    padding:50px;
}
.paddingContactImage{
    padding: 50px 50px 15px 50px;
}

.marginTxt{
    margin-top:20px;
}
.addHead{
    color: green;
    text-decoration: underline;
}

.phoneNumTxt{
    font-size: 20px;
    color: green;
    position: absolute;
    width: max-content;
    left: 66px;
    top: 10px;
    font-family: inherit;
}
.joinContact{
    cursor:  pointer !important;
    float: left;
    width: 100%;
    margin: 15px 0px;
}