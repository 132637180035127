.sign-in-button {
    background-color: #fff;
    text-align: center;
    box-shadow: none;
    border: none;
    margin-top: 0px;
    padding: 12px 22px;
    border-radius: 3px;
    font-size: 18px;
    width:100%;
    color: mediumseagreen;
    text-transform: uppercase;
    font-size: 1rem;
}

@media only screen and (min-width:300px) and (max-width:770px) {
    .copyright-text{
        text-align: center;
        font-size: 12px;
    }
    .sign-in-button {
        margin-top: 36px;
    }
}

.name {
    color: #fff;
}

.footer-row {
    padding: 33px 0;
}

.content-box {
    display: flex;
}
@media only screen and (min-width:375px) and (max-width:575px){
    .content-box{
        justify-content: center;
    }
    .icons{
     text-align: center;
    }
}
@media only screen and (min-width:575px) and (max-width:768px){
   .icons i{
      padding-right: 8px !important;
   }
   .icons{
       font-size: 20px !important;
   }
   .content{
       margin-right:0px !important;
   }
}
.content-box span i {
    color: mediumaquamarine;
    font-size: 2rem;
    margin-top: 5px;
    margin-right: 12px;
}

.icons {
    color: mediumaquamarine;
    font-size: 24px;
}

.icons i {
    padding-right: 16px;
}
@media only screen and (min-width:300px) and (max-width:992px){
    .single-footer-widget p{
        display: none;
    } 
}
.single-footer-widget p {
    font-size: 12px;
    color: #666666;
}

.content {
    margin-right: 2rem;
}

.content span {
    font-size: 12px;
    color: black;
}

.content p {
    font-size: 12px;
    color: #666666;
}

.content2 span {
    font-size: 12px;
    color: #666666;
}

.content2 p {
    font-size: 12px;
    color: #666666;
}

@media only screen and (max-width:400px) {
    .content {
        margin-right: 0% !important;
    }
    .single-footer-widget {
        font-size: 10px !important;
    }
    .content-box span i {
        font-size: 2rem !important;
    }
    .content span {
        font-size: 10px !important;
    }
    .content2 span {
        font-size: 10px;
    }
    .content2 p {
        font-size: 10px;
    }
}
.social-icon:hover{
    color: mediumaquamarine;
}
.navbar-text i {
padding-right: 1.5rem;
}
.icons i:hover{
  color: #ddd;
}

/*Letter box starts*/
.letter-box {
    padding: 50px !important;
}
.letter-box h1 {
    font-size: 24px !important;
}
.letter-box {
    background: linear-gradient( to bottom, mediumseagreen, #079669);
    padding: 30px;
}

.letter-box h1 {
    color: white;
    font-weight: 400;
    font-size: 24px;
}

.letter-box .border {
    border: 1px solid #ddd !important;
    border-radius: 3px;
}

.emailTextField{
    background: transparent !important;
    position: absolute;
    top: 0px;
    width:100%;
    color:white;
   
}
.topAlign{
    position: relative;
    top: 23px
}
.newsLetterPos{
    position: relative;
    top: 20px;
}
::-webkit-input-placeholder { /* Edge */
    color: white;
  }
  @media only screen and (min-width:300px) and (max-width:767px) {
    .emailTextField {
        position: unset;
    }
    .labelValue{
        position: relative;
        width: 100%;
    }
}
.errorMsg{
    color:red;
    position: relative;
    top: 40px;
}

input::placeholder { color: white; }
/*Letter box ends*/