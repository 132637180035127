    /* Home Page */
    .banner-area {
        background-image: url(../../assets/banner-img.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #home{
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    @media only screen and (min-width:300px) and (max-width:576px){
    .banner-area{
        background-position: 83%;
    }
}
@media only screen and (min-width:576px) and (max-width:992px){
    .banner-area{
        background-position: 73%;
    }
}


    @media only screen and (min-width:300px) and (max-width:1000px) {
        #home .row {
            height: 0% !important;
        }
    }
    
    .relative {
        position: relative;
    }
    
    .img-fluid {
        max-width: 100%;
        height: auto;
    }
    
    .banner-content .title-top {
        color: white;
        font-size: 4rem;
        letter-spacing: 30px;
        font-weight: 400;
    }
    
    .no-padding {
        padding: 0%;
    }
    /* Home section title area */
    
    @media only screen and (min-width:300px) and (max-width:576px) {
        .banner-content .title-top {
            font-size: 3rem;
            letter-spacing: 20px;
            text-align: center;
        }
    }
    @media only screen and (min-width:576px) and (max-width:992px) {
        .banner-content .title-top {
            font-size: 5rem;
            letter-spacing: 20px;
            text-align: center;
        }
    }
    
    /* end of Home Page */

    /*About us in home page*/
    @media only screen and (min-width:300px) and (max-width:578px) {
        #about {
            padding: 42px 0 !important;
        }
        .about-us {
            background-position: inherit !important;
        }
        #about-heading p {
            margin-bottom: 0px !important;
            font-size: 7px !important;
        }
    }
    
    @media only screen and (min-width:578px) and (max-width:768px) {
        #about {
            padding: 45px 0 !important;
        }
        .about-us {
            background-position: inherit !important;
        }
        #about-heading p {
            margin-bottom: 0px !important;
            font-size: 12px !important;
        }
    }
    
    @media only screen and (min-width:768px) and (max-width:968px) {
        #about {
            padding: 61px 0 !important;
        }
        .about-us {
            background-position: inherit !important;
        }
        #about-heading p {
            margin-bottom: 0px !important;
            font-size: 1rem !important;
        }
    }
    
    @media only screen and (min-width:968px) and (max-width:1200px) {
        #about {
            padding: 73px 0 !important;
        }
        .about-us {
            background-position: inherit !important;
        }
        #about-heading p {
            margin-bottom: 0px !important;
            font-size: 1rem !important;
        }
    }
    #about {
        padding: 100px 0;
    }
    
    .about-us {
        background-image: url(../../assets/banner-about.jpg);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    
    @media only screen and (min-width:300px) and (max-width:578px) {
        .about-title h1 {
            font-size: 1rem !important;
        }
    }
    
    .about-title h1 {
        font-size: 2.5rem;
    }
    
    .about-title p {
        font-size: 1rem;
        font-weight: 300;
    }
    /*End About us in home page */
     
      /*shop Now starts*/
    .outline-box {
        background: linear-gradient( to bottom, mediumseagreen, #079669);
        padding: 50px;
    }
    
    .outline-box h1 {
        color: #fff;
    }
    
    .outline-box p {
        color: white;
        font-weight: 300;
    }
    
    .btn-lg {
        background: white;
        border: none !important;
        padding: 13px 54px !important;
        box-shadow: none;
        
    }
    
    .btn-lg span {
        color: mediumseagreen;
        text-transform: uppercase;
        font-size: 1.5rem;
    }
    
    @media only screen and (min-width:300px) and (max-width:770px) {
        .button-box {
            padding: 0px 7px !important;
        }
        .btn-lg {
            padding: 10px 38px !important;
        }
        .btn-lg span {
            font-size: 18px !important;
        }
    }
    /*shop Now ends*/

    /*Service starts*/
    @media only screen and (min-width:300px) and (max-width:768px) {
        .service-side p {
            font-size: 11px !important;
        }
    }
    
    @media only screen and (min-width:768px) and (max-width:1000px) {
        .service-side {
            padding: 12px 22px !important;
        }
        .service h1 {
            margin-bottom: 1rem !important;
        }
        p {
            font-size: 12px !important;
        }
        .gradient span {
            font-size: 12px !important;
            padding: 13px 28px !important;
        }
    }
    
    @media only screen and (min-width:998px) and (max-width:1200px) {
        .service-side {
            padding: 16px 50px !important;
        }
    }
    
    @media only screen and (min-width:300px) and (max-width:768px) {
        .service-side {
            padding: 20px 0px !important;
        }
        .service-inner{
            padding: 15px 0;
        }
        .img-center{
            width: 60%;
        }
    }
    
    @media only screen and (min-width:770px) and (max-width:1050px) {
        .button-box {
            padding: 0px 26px;
        }
        .btn-lg {
            padding: 10px 25px !important;
            margin-top: 3rem;
        }
        .btn-lg span {
            font-size: 1rem;
        }
    }
    
    .button-box {
        padding: 0px 26px;
    }
    
    .service-inner {
        padding: 100px 0;
    }
    
    
    
    .service p {
        color: #666666;
    }
    
    .service-side {
        padding: 50px 50px;
    }
    
    .gradient span {
        background: linear-gradient( to bottom, mediumseagreen, #079669);
        padding: 17px 50px;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 700;
        border-radius: 5px;
    }
    /*Service Ends*/

 .marginRow{
 }

 .videoFile{
     width:100%;
     height:100%;
     object-fit: cover;
 }


#videoMessage{
    width: 100%; 
    height: 100%;
    position: absolute; 
    top: 0; 
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutPTag{
    padding: 0% 25% 0% 25%;
}
.shopeNow{
    background-color: #fff;
    text-align: center;
    box-shadow: none;
    border: none;
    margin-top: 0px;
    padding: 12px 22px;
    border-radius: 3px;
    font-size: 18px;
    width: 100%;
    color: mediumseagreen;
    text-transform: uppercase;
    font-size: 1rem;
}

 .btn-primary:hover {
    color: mediumseagreen !important;
    background-color: #fff !important;
    border-color:#fff !important;
}
.wegomeds{
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
}
.wegomeds1{
    font-size: 1rem;
    color: white;
    font-weight: 600;
    position: relative;
    left:100px;
}
@media screen and (max-width: 1100px) and (min-width: 992px) {
    .wegomeds1 {
        position: relative;
        left:45px;
    }
  }

  .name-class {
    background: url(../../assets/wegoo1.mp4) no-repeat;
    background-position: center;
    height: 686px;
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
    }
    .name-class video {
    height: 473px;
    position: absolute;
    top: 148px;
    left: 3px;
    width: 100%;
    }
.videoFile{
    width:100%;
    height:100%;
}