.side-img {
    width: 100% !important;
}

.navbar img {
    margin-bottom: 3rem;
}
.info-left {
   cursor: pointer;
}

@media only screen and (min-width:300px) and (max-width:770px) {
    .info {
        border: none !important;
    }
}

@media only screen and (min-width:300px) and (max-width:770px) {

    .info .info-left {
        position: absolute !important;
        top: 5px !important;
    }
    .info .info-left .box {
        background: linear-gradient(
    180deg
    ,rgb(51, 196, 147),mediumseagreen);
    font-size: 1.5rem !important;
    padding: 0px 13px 0px 10px !important;
        /* font-weight: 700; */
    }
}
@media only screen and (min-width:300px) and (max-width:690px) {
    .info-right{
        position: relative !important;
        top:20px;   
    }

}

@media only screen and (min-width:300px) and (max-width:770px) {
    .info-last .widget-subheading {
        color: mediumseagreen;
    }
}

@media only screen and (min-width:300px) and (max-width:770px) {
    .info-right .widget-subheading {
        color: mediumseagreen;
        margin-left: 3px;
    }
}

@media only screen and (min-width:300px) and (max-width:770px) {
    .info-right .widget-heading {
        display: none !important;
    }
}

@media only screen and (min-width:300px) and (max-width:770px) {
    .info-last .widget-heading {
        display: none !important;
    }
}

.side-logo .side-img img {
    max-width: 50%;
    padding: 12px 0;
}

@media only screen and (min-width:300px) and (max-width:1000px) {
    .side-logo .side-img img {
        max-width: 150%;
        position: relative;
        left: -17px;
    }
}

@media only screen and (min-width:400px) and (max-width:770px) {
    .fa-bars {
        margin-top: 1rem;
    }
}

.fa-bars {
    font-size: 2rem;
}

#navbarText ul li a {
    color: mediumaquamarine;
}

#navbarText ul {
    margin-right: auto !important;
}

#navbarText ul li a {
    font-size: 15px;
}

@media only screen and (min-width:300px) and (max-width:1000px) {
    .navbar-text {
        margin-left: 0% !important;
    }
}

.navbar-text {
    margin-left: 10%;
    display: flex;
}


.info {
    margin-top: 1rem;
    border-bottom: 5px solid #ddd;
}
@media only screen and (min-width:1500px) and (max-width:2652px){
    .info{
        border-bottom: 3px solid #afafaf;
    }
    .info-last{
        padding-right: 77px;
    }
}
@media only screen and (min-width:2000px) and (max-width:2652px){
    .btn-lg{
        margin-top: 0px !important;
    }
}
.info {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding-bottom: 1rem;
}

.info .info-left {
    align-items: center;
}
.info .info-left .box{
    background: linear-gradient(180deg,rgb(51, 196, 147),mediumseagreen);
    padding: 9px 60px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
}

.info-right {
    font-size: 0.7rem;
}

.info-last {
    font-size: 0.7rem;
    padding-right: 11px;
}
.content-right{
    margin-right: 1rem;
}
.info-last-middle{
    padding-right: 15px;
}
@media only screen and (min-width:300px) and (max-width:768px){
    .content-right{
        margin-right: .5rem;
    }
    .info-last{
        padding-right: 0px !important;
    }

}
@media only screen and (min-width:300px) and (max-width:768px){
.content-right .fa-mobile-alt{

}
.info-last-middle{

}
}
@media only screen and (min-width:768px) and (max-width:992px){
  .info .info-left .box{
      padding: 8px 31px;
  }
  .info .info-right{
      padding-right: 0rem !important;
  }
 
  .info-last-middle{
      padding-right: 15px;
  }
}  

@media only screen and (min-width:300px) and (max-width:700px) {
    .info .info-last {
        font-size: 12px;
    }
    .content-left .fas {
        margin-right: 0% !important;
    }
    
    .content-right .fa-mobile-alt {
        padding-right: 0% !important;
    }
}

.info .info-right {
    align-items: center;
    margin-left: auto;
    padding-right: 1rem;
    display: flex;

}


.content-left img {
    width: 100%;
}

.content-right img {
    width: 100%;
}

@media only screen and (min-width:300px) and (max-width:900px) {
    .home-section .wego {
        font-size: 5rem !important;
        margin-top: 5rem !important;
        margin-left: 5rem !important;
    }
}

@media only screen and (min-width:300px) and (max-width:495px) {
    .sider-banner {
        width: 100% !important;
        margin-top: 5rem;
        display: none;
    }
}

@media only screen and (min-width:300px) and (max-width:800px) {
    .col-md-2 {
        max-width: 20% !important;
    }
}

@media only screen and (min-width:1200px) and (max-width:1500px) {
    .col-md-2 {
        max-width: 50% !important;
    }
}

@media only screen and (min-width:300px) and (max-width:800px) {
    .col-md-10 {
        max-width: 79% !important;
        margin-top: .5rem !important;
    }
}

.navbar-toggler {
    margin-left: auto;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}


@media only screen and (min-width:300px) and (max-width:340px) {
    .info-right .widget-subheading {
        font-size: 9px !important;
    }
}

@media only screen and (min-width:300px) and (max-width:340px) {
    .info-last .widget-subheading {
        font-size: 9px;
    }
}

@media only screen and (min-width:340px) and (max-width:390px) {
    .info-right .widget-subheading {
        font-size: 10px;
       
    }
}

@media only screen and (min-width:340px) and (max-width:390px) {
    .info-last .widget-subheading {
        font-size: 10px;
    }
}

@media only screen and (min-width:390px) and (max-width:416px) {
    .info-right .widget-subheading {
        margin-right: 7px;
        font-size: 10px;
    }
}

@media only screen and (min-width:390px) and (max-width:416px) {
    .info-last .widget-subheading {
        font-size: 10px;
    }
}

@media only screen and (min-width:390px) and (max-width:416px) {
    .fa-bars {
        margin-top: 0% !important;
    }
}



@media only screen and (min-width:416px) and (max-width:430px) {
    .info-last .widget-subheading {
        font-size: 12px;
    }
}

@media only screen and (min-width:416px) and (max-width:430px) {
    .fa-bars {
        margin-top: 0% !important;
    }
}

@media only screen and (min-width:430px) and (max-width:475px) {
    .fa-bars {
        margin-top: 0% !important;
    }
}



@media only screen and (min-width:430px) and (max-width:475px) {
    .info-right .widget-subheading {
        margin-right: 12px;
    }
}

@media only screen and (min-width:476px) and (max-width:770px) {
    .info-right .widget-subheading {
        margin-right: 12px;
    }
}

@media only screen and (min-width:476px) and (max-width:770px) {
    .fa-bars {
        margin-top: 0% !important;
    }
}

@media only screen and (min-width:992px) and (max-width:1200px){
    .side-logo .side-img img{
        max-width: 145%;
        position: relative;
        left: -20px;
    }
}
@media only screen and (min-width:1200px) and (max-width:1400px){
.side-logo .side-img img{
max-width: 128%;
}
}

@media only screen and (min-width:1700px) and (max-width:2000px) {
    .container {
        max-width: 1500px !important;
    }
    .side-logo .side-img img {
        padding: 24px 0;
    }
    .info-right {
        font-size: 20px !important;
    }
    .info-last {
        font-size: 20px !important;
    }
    .content-left {
        margin-left: 64% !important;
    }
    .info-left {
        font-size: 20px !important;
    }
    .content-right .fa-mobile-alt {
        font-size: 20px !important;
    }
    .content-left .fas {
        font-size: 20px !important;
    }
    .info {
        margin-top: 2rem !important;
        padding-bottom: 1rem !important;
    }
    #navbarText ul li a {
        font-size: 20px !important;
    }
    .navbar-text i {
        font-size: 18px !important;
    }
    .navbar-text {
       
    }
    #navbarText ul {
        margin-right: 0% !important;
    }
}

@media only screen and (min-width:2000px) and (max-width:2652px) {
    .container {
        max-width: 2000px !important;
    }
}

@media only screen and (min-width:2000px) and (max-width:2652px) {
    .side-logo .side-img img {
        max-width: 100%;
        padding: 12px 0;
    }
}

@media only screen and (min-width:2000px) and (max-width:2652px) {
    .info .info-left span {
        font-size: 30px;
    }
}

.content-left .fas {
    font-size: 15px;
    margin-right: 12px;
}

.content-right .fa-mobile-alt {
    font-size: 15px;
    padding-right: .5rem;
}

@media only screen and (min-width:770px) and (max-width:900px) {
    .content-left {
        margin-left: 52% !important;
    }
    .content-left .fas {
        margin-right: 7px !important;
    }
}

@media only screen and (min-width:900px) and (max-width:991px) {
    .content-left {
        margin-left: 55% !important;
    }
    .content-left .fas {
        margin-right: 12px !important;
    }
}

@media only screen and (min-width:700px) and (max-width:770px) {
    .content-left {
        margin-left: 44% !important;
    }
}

@media only screen and (min-width:1000px) and (max-width:1200px) {
    .content-left {
        margin-left: 66% !important;
    }
    .content-left .fas {
        margin-right: 12px !important;
    }
    .navbar-text {
        margin-left: 18% !important;
    }
    .navbar-text i {
        padding-right: 1rem !important;
    }
}

@media only screen and (min-width:1200px) and (max-width:1500px) {
    .navbar-text i {
        padding-right: 1.5rem !important;
    }
}

@media only screen and (min-width:1400px) and (max-width:1500px) {
    .content-left {
        margin-left: 74% !important;
    }
}

@media only screen and (min-width:1500px) and (max-width:1700px) {
    .info {
        margin-top: 2rem;
    }
    .info .info-left {
        font-size: 18px;
    }
    .info-right {
        font-size: 18px;
    }
    .content-left .fas {
        font-size: 18px;
    }
    .content-right .fa-mobile-alt {
        font-size: 18px;
    }
    .info-last {
        font-size: 1rem;
    }
    .content-left {
        margin-left: 63%;
    }
}

@media only screen and (min-width:2000px) and (max-width:2652px) {
    .info {
        margin-top: 2rem !important;
        padding-bottom: 1rem !important;
    }
    .content-left {
        margin-left: 62% !important;
    }
    .content-left .fas {
        font-size: 30px !important;
        margin-right: 10px !important;
    }
    .info-right {
        font-size: 28px !important;
    }
    .content-right .fa-mobile-alt {
        font-size: 30px !important;
        padding-right: .5rem !important;
    }
    .info-last {
        font-size: 28px !important;
        margin-left: 1rem !important;
    }
    #navbarText ul li a {
        font-size: 30px !important;
    }
    #navbarText ul {
        margin-right: 0% !important;
    }
    .navbar-text i {
        font-size: 30px !important;
        padding-right: 3rem !important;
    }
}

@media only screen and (min-width:2000px) and (max-width:2652px) {
    .banner-content .title-top {
        font-size: 8rem !important;
        letter-spacing: 90px !important;
    }
}

@media only screen and (min-width:2000px) and (max-width:2652px) {
    .outline-box h1 {
        font-size: 3rem !important;
    }
    .outline-box p {
        font-size: 1.5rem !important;
    }
    .button-box {
        padding: 38px 170px !important;
    }
}

@media only screen and (min-width:2000px) and (max-width:2652px) {
    .service-side {
        padding: 21px 50px !important;
    }
    .service h1 {
        font-size: 4rem;
    }
    .service p {
        font-size: 2rem;
    }
    .gradient span {
        padding: 24px 75px !important;
        font-size: 2.5rem !important;
    }
    .letter-box {
        padding: 50px !important;
    }
    .letter-box h1 {
        font-size: 35px !important;
    }
    .name {
        font-size: 20px !important;
    }
    .sign-in-button {
        font-size: 18px !important;
        padding: 8px 22px !important;
    }
    .single-footer-widget p {
        font-size: 20px !important;
    }
    .content-box span i {
        font-size: 3rem !important;
    }
    .content span {
        font-size: 20px !important;
    }
    .content2 span {
        font-size: 20px !important;
    }
    .icons {
        font-size: 60px !important;
    }
    .icons i {
        padding-right: 25px !important;
    }
}
/* Home Page */
.banner-area {
  
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
#home{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
@media only screen and (min-width:300px) and (max-width:576px){
.banner-area{
    background-position: 83%;
}
}
@media only screen and (min-width:576px) and (max-width:992px){
.banner-area{
    background-position: 73%;
}
}


@media only screen and (min-width:300px) and (max-width:1000px) {
    #home .row {
        height: 0% !important;
    }
}

.relative {
    position: relative;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.banner-content .title-top {
    color: white;
    font-size: 5rem;
    letter-spacing: 30px;
    font-weight: 400;
}

.no-padding {
    padding: 0%;
}
/* Home section title area */

@media only screen and (min-width:300px) and (max-width:576px) {
    .banner-content .title-top {
        font-size: 3rem;
        letter-spacing: 20px;
        text-align: center;
    }
}
@media only screen and (min-width:576px) and (max-width:992px) {
    .banner-content .title-top {
        font-size: 5rem;
        letter-spacing: 20px;
        text-align: center;
    }
}

/* end of Home Page */

@media only screen and (min-width:300px) and (max-width:578px) {
    #about {
        padding: 42px 0 !important;
    }
    .about-us {
        background-position: inherit !important;
    }
    #about-heading p {
        margin-bottom: 0px !important;
        font-size: 7px !important;
    }
}

@media only screen and (min-width:578px) and (max-width:768px) {
    #about {
        padding: 45px 0 !important;
    }
    .about-us {
        background-position: inherit !important;
    }
    #about-heading p {
        margin-bottom: 0px !important;
        font-size: 12px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:968px) {
    #about {
        padding: 61px 0 !important;
    }
    .about-us {
        background-position: inherit !important;
    }
    #about-heading p {
        margin-bottom: 0px !important;
        font-size: 1rem !important;
    }
}

@media only screen and (min-width:968px) and (max-width:1200px) {
    #about {
        padding: 73px 0 !important;
    }
    .about-us {
        background-position: inherit !important;
    }
    #about-heading p {
        margin-bottom: 0px !important;
        font-size: 1rem !important;
    }
}

@media only screen and (min-width:1200px) and (max-width:1500px) {
    .about-us {
        background-position: inherit !important;
    }
    #about-heading p {
        margin-bottom: 0px !important;
        font-size: 1.3rem !important;
    }
}

@media only screen and (min-width:1500px) and (max-width:2000px) {
    .about-us {
        background-position: inherit !important;
    }
    #about-heading p {
        margin-bottom: 0px !important;
        font-size: 1.5rem !important;
    }
    #about {
        padding: 116px 0 !important;
    }
    .about-title h1 {
        font-size: 4.5rem !important;
        margin-bottom: 2rem !important;
    }
}

@media only screen and (min-width:2000px) and (max-width:2652px) {
    .about-us {
        background-position: inherit !important;
    }
    #about-heading p {
        margin-bottom: 0px !important;
        font-size: 2.5rem !important;
    }
    #about {
        padding: 174px 0 !important;
    }
    .about-title h1 {
        font-size: 6.5rem !important;
        margin-bottom: 2rem !important;
    }
}

#about {
    padding: 100px 0;
}

.about-us {
  
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

@media only screen and (min-width:300px) and (max-width:578px) {
    .about-title h1 {
        font-size: 1rem !important;
    }
}

.about-title h1 {
    font-size: 2.5rem;
}

.about-title p {
    font-size: 1rem;
    font-weight: 300;
}
/* end of about us */

.outline-box {
    background: linear-gradient( to bottom, mediumseagreen, #079669);
    padding: 50px;
}

.outline-box h1 {
    color: #fff;
}

.outline-box p {
    color: white;
    font-weight: 300;
}

.btn-lg {
    background: white;
    border: none !important;
    padding: 13px 54px !important;
    box-shadow: none;
    
}
.iconColor{
    color:inherit !important;
}

.btn-lg span {
    color: mediumseagreen;
    text-transform: uppercase;
    font-size: 1.5rem;
}

@media only screen and (min-width:300px) and (max-width:770px) {
    .button-box {
        padding: 0px 7px !important;
    }
    .btn-lg {
        padding: 10px 38px !important;
    }
    .btn-lg span {
        font-size: 18px !important;
    }
}

@media only screen and (min-width:300px) and (max-width:768px) {
    .service-side p {
        font-size: 11px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1000px) {
    .service-side {
        padding: 12px 22px !important;
    }
    .service h1 {
        margin-bottom: 1rem !important;
    }
    p {
        font-size: 12px !important;
    }
    .gradient span {
        font-size: 12px !important;
        padding: 13px 28px !important;
    }
}

@media only screen and (min-width:998px) and (max-width:1200px) {
    .service-side {
        padding: 16px 50px !important;
    }
}

@media only screen and (min-width:300px) and (max-width:768px) {
    .service-side {
        padding: 20px 0px !important;
    }
}

@media only screen and (min-width:770px) and (max-width:1050px) {
    .button-box {
        padding: 0px 26px;
    }
    .btn-lg {
        padding: 10px 25px !important;
        margin-top: 3rem;
    }
    .btn-lg span {
        font-size: 1rem;
    }
}

.button-box {
    padding: 0px 26px;
}

.service-inner {
    padding: 100px 0;
}



.service p {
    color: #666666;
}

.service-side {
    padding: 50px 50px;
}

.gradient span {
    background: linear-gradient( to bottom, mediumseagreen, #079669);
    padding: 17px 50px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 5px;
}

.letter-box {
    background: linear-gradient( to bottom, mediumseagreen, #079669);
    padding: 30px;
}

.letter-box h1 {
    color: white;
    font-weight: 400;
    font-size: 24px;
}

.letter-box .border {
    border: 1px solid #ddd !important;
    border-radius: 3px;
}


.sign-in-button {
    background-color: #fff;
    text-align: center;
    box-shadow: none;
    border: none;
    margin-top: 0px;
    padding: 8px 22px;
    border-radius: 3px;
    font-size: 12px;
}

@media only screen and (min-width:300px) and (max-width:770px) {
    .sign-in-button {
        margin-top: 14px;
    }
}

.name {
    color: #fff;
}

.footer-row {
    padding: 33px 0;
}

.content-box {
    display: flex;
}
@media only screen and (min-width:375px) and (max-width:575px){
    .content-box{
        justify-content: center;
    }
    .icons{
     text-align: center;
    }
}
@media only screen and (min-width:575px) and (max-width:768px){
   .icons i{
      padding-right: 8px !important;
   }
   .icons{
       font-size: 20px !important;
   }
   .content{
       margin-right:0px !important;
   }
}
.content-box span i {
    color: mediumaquamarine;
    font-size: 2rem;
    margin-top: 5px;
    margin-right: 12px;
}

.icons {
    color: mediumaquamarine;
    font-size: 24px;
}

.icons i {
    padding-right: 16px;
}
@media only screen and (min-width:300px) and (max-width:992px){
    .single-footer-widget p{
        display: none;
    } 
}
.single-footer-widget p {
    font-size: 12px;
    color: #666666;
}

.content {
    margin-right: 2rem;
}

.content span {
    font-size: 12px;
    color: black;
}

.content p {
    font-size: 12px;
    color: #666666;
}

.content2 span {
    font-size: 12px;
    color: #666666;
}

.content2 p {
    font-size: 12px;
    color: #666666;
}

@media only screen and (max-width:400px) {
    .content {
        margin-right: 0% !important;
    }
    .single-footer-widget {
        font-size: 10px !important;
    }
    .content-box span i {
        font-size: 2rem !important;
    }
    .content span {
        font-size: 10px !important;
    }
    .content2 span {
        font-size: 10px;
    }
    .content2 p {
        font-size: 10px;
    }
}
.social-icon:hover{
    color: mediumaquamarine;
    cursor: pointer;
}
.navbar-text i {
padding-right: 1.5rem;
}
.icons i:hover{
  color: #ddd;
}
/* about us page styles */
.About-us .about-box-shadow{
    background-color: #fff;
    border-radius: 50px;
} 
.About-us{
    padding: 60px 0px;
}
@media only screen and (min-width:300px) and (max-width:556px){
    .about-logo{
        padding: 41px !important;
    }
    .about-para{
        padding: 0px !important;
    }
}
@media only screen and (min-width:556px) and (max-width:768px){
    .about-para{
        padding: 0px !important;
    }
}
@media only screen and (min-width:768px) and (max-width:1200px){
    .about-para{
        padding: 25px 0 !important;
    }
    .about-logo{
        padding: 42px !important;
    }
}
.about-logo{
padding: 71px;
}
.about-para p{
    margin-top: 1rem;
    font-size: 20px;
    padding: 20px;
}
.aboutpage-title{
    margin-bottom: 3rem;
}
.vission-mission{
    background-color:#f3f3f3;
    padding: 0px 25px;
    margin-bottom: 3rem;
    height:130px;
}
.vision-img{
    margin-top: 17px;
    margin-right: 20px;
}
.about-para{
    padding:53px 0px;
}
.vision-para{
    margin-top: 10px;
}
.vision-para h5{
    font-size: 1.2rem;
}
.vision-para p{
    font-size: 15px;
}
.rental-div{
    padding: 60px 0;
}
.rental-div h1{
    font-size: 4rem;
    margin-bottom: 4rem;
}
.rental-item{
    margin-bottom:30px;
    background-color: #fff;
    border-radius: 14%;
    padding-left: 70px;
    padding-top: 12px;
    padding-bottom: 20px;
}
.rental-item .rental-head{
    text-align: center;
    margin-right: 6rem;
}
.button{
    background-color: #fff;
    width: 30%;
    margin-left: 39%;
padding: 20px;
text-align: center;
font-size: 2rem;
font-weight: 700;
border-radius: 40px
}
@media only screen and (min-width:300px) and (max-width:776px){
    .button{
        width: auto;
        margin-left: auto;
    }
}
.target{
    padding-bottom: 2rem;
    padding-left: 84px !important;
    padding-right: 94px !important;
}
@media only screen and (min-width:300px) and (max-width:330px){
    .target{
        padding-right: 0px !important;
        padding-left: 34px  !important;
    }
    .vision-img{
        margin-top: 2rem !important;
    }
}
@media only screen and (min-width:330px) and (max-width:400px){
    .target{
        padding-right: 0px !important;
        padding-left: 30px  !important;
    }
    .vision-img{
        margin-top: 1rem !important;
    }
}
@media only screen and (min-width:400px) and (max-width:460px){
    .vision-img{
        margin-top: 3rem !important;
    }
}
@media only screen and (min-width:460px) and (max-width:500px){
    .vision-img{
        margin-top: 36px !important;
    }
}
@media only screen and (min-width:544px) and (max-width:765px){
.vision-img{
width: 20% !important;
}
.vision-img{
margin-top: 30px !important;
}
}
@media only screen and (min-width:765px) and (max-width:992px){
    .vision-img{
        margin-top: 38px !important;
    }
}
@media only screen and (min-width:992px) and (max-width:1190px){
.vision-img{
    width: 20% !important;
}
}
@media only screen and (min-width:1190px) and (max-width:1382px){
.vision-img{
    width: 16% !important;
    margin-top: 15px !important;
}
}
@media only screen and (min-width:1382px) and (max-width:1400px){
.vision-img{
    width: 13% !important;
    margin-top: 12px !important;
}
}

@media only screen and (min-width:1400px) and (max-width:1680px){
.vision-img{
    width: 10% !important;
    margin-top: 12px !important;
}
}


@media only screen and (min-width:1680px) and (max-width:2030px){
.vision-img{
    width: 10% !important;
    margin-top: 16px !important;
}
}

@media only screen and (min-width:2030px) and (max-width:2652px){
.vision-img{
    width: 16% !important;
    margin-top: 16px !important;
}
.vission-mission{
    padding: 35px 100px;
}
.vision-para h5{
    font-size: 3.2rem;
}
.vision-para p{
    font-size: 23px;
}
.vision-para{
    margin-top: 0px;
}
}
@media only screen and (min-width:1500px) and (max-width:1940px){
.about-para p{
    font-size: 30px;
}
}
@media only screen and (min-width:1940px) and (max-width:2000px){
.about-para{
    margin-top: 6rem;
}
}

@media only screen and (min-width:2000px) and (max-width:2652px){
.about-para p{
    font-size: 53px;
}
}

/* end of about us */
.home-care-img{
    background: #fff;
    border-radius: 14%;
    padding: 30px;
}
@media only screen and (min-width:992px) and (max-width:2256px){
   .care-images{
       width: 20% !important;
   }
}
.care-images{
    width: 100%;
    margin-bottom: 5rem;
}
.service-header h1{
    font-weight: 600px;
    font-size: 3.5rem;
    padding: 20px 0;
}
.title h2{
    font-weight: 600;
    font-size: 3rem;
    padding: 40px 0;
}
.nursing-care{
    background: #fff;
    border-radius: 40px;
    padding: 40px;
}
.nursing-care h1{
    font-weight: 400;
    font-size: 3rem;
    letter-spacing: 3px;
}
.nursing-care p{
    font-weight: 500;
}
.massage{
    margin-top: 4rem !important;
    margin-bottom: 3rem;
}
.bg-light{
    padding-bottom: 7rem;
}
.change{
   margin-bottom: 2rem;
}
/* services correction */
@media only screen and (min-width:300px) and (max-width:344px){
.rental-item{
padding-left: 50px;
}
.rental-item .rental-head{
   margin-right: 3rem;
}
}
/* contact */
.contact-title{
margin-bottom: 3rem;
}
.contact100-form {
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding-bottom: 68px;
}
.validate-input {
position: relative;
}
.rs1-wrap-input100 {
width: calc((100% - 30px)/2);
}
.validate-input {
position: relative;
}
.rs1-wrap-input100 {
width: calc((100% - 30px)/2);
}
.wrap-input100 {
border-bottom: 2px solid #d9d9d9;
padding-bottom: 13px;
margin-bottom: 65px;
}
.label-input100 {
font-family: Poppins-Regular;
font-size: 15px;
color: #999;
line-height: 1.5;
padding-left: 5px;
}

input.input100 {
height: 40px;
}
.input100 {
display: block;
width: 100%;
background: 0 0;
font-family: Poppins-SemiBold;
font-size: 18px;
color: #555;
line-height: 1.2;
padding: 0 5px;
}
input {
outline: none;
border: none;
}
.focus-input100 {
position: absolute;
display: block;
width: 100%;
height: 100%;
top: 0;
left: 0;
pointer-events: none;
}
.focus-input100::before{
content: "";
display: block;
position: absolute;
left: 0;
height: 2px;
bottom: -2px;
width: 0;
transition: all .4s;
background: linear-gradient(teal,mediumseagreen);
}
.container-contact100-form-btn {
width: 100%;
display: flex;
flex-wrap: wrap;
margin-top: -25px;
}
.contact100-form-btn {
display: flex;
justify-content: center;
align-items: center;
padding: 0 20px;
min-width: 160px;
height: 50px;
background:linear-gradient(teal,mediumseagreen);
border-radius: 7px;
font-family: Poppins-Medium;
font-size: 16px;
color: #fff;
line-height: 1.2;
}
button {
outline: none!important;
border: none;
background: 0 0;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../../assets/icon.png');
    width: 52px;
}

.navbar-light .navbar-toggler {
    border:none;
    color: none;
}
.nav-links{
  font-size:15px;
  color: mediumaquamarine;
}


.joinHref, .contactLink a.joinHref:hover {
    color:white;
    text-decoration: none !important;
    cursor: pointer;
  }
  
  .paddingRt{
      padding-right:10px;
  }

 

 
@media only screen and (min-width:975px) and (max-width:1200px) {
    .banner-content .title-top {
        font-size: 3rem !important;
    }
}
.test .active {
color:green !important;
font-weight: bold !important;
text-decoration: underline !important;
padding: 10px 15px 10px 15px !important;
border-radius: 10px;
}

.general{
    padding: 10px 15px 10px 15px !important;
}

 #location a:hover{
    text-decoration: none !important;
    color: black !important;
}

#location a:link{
    text-decoration: none !important;
    color: black !important;
}

#location a{
    text-decoration: none !important;
    color: black !important;
}    

.callUS{
    text-decoration: none;
    color:inherit;
}