.home-care-img{
    background: #fff;
    border-radius: 14%;
    padding: 30px;
}

@media only screen and (min-width:992px) and (max-width:2256px){
   .care-images{
       width: 20% !important;
   }
}
.care-images{
    width: 100%;
    margin-bottom: 5rem;
}
.service-header h1{
    font-weight: 600px;
    font-size: 3.5rem;
    padding: 20px 0;
}
.title h2{
    font-weight: 600;
    font-size: 3rem;
    padding: 40px 0;
}
.nursing-care{
    background: #fff;
    border-radius: 40px;
    padding: 40px;
}
.nursing-care h1{
    font-weight: 400;
    font-size: 3rem;
    letter-spacing: 3px;
}
.nursing-care p{
    font-weight: 500;
}
.massage{
    margin-top: 4rem !important;
    margin-bottom: 3rem;
}
.bg-light{
    padding-bottom: 7rem;
}
.change{
   margin-bottom: 2rem;
}
/* services correction */
@media only screen and (min-width:300px) and (max-width:344px){
.rental-item{
padding-left: 50px;
}
.rental-item .rental-head{
   margin-right: 3rem;
}
}
.img-fluid {
    max-width: 100%;
    height: auto;
}

.txtUnderLine{
    color: white;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    background: linear-gradient( to bottom, mediumseagreen, #079669);
    border: 1px solid green;
}

.rmvUnderLine{
    color: green;
    text-align: center;
    padding: 10px;
    border: 1px solid green;
    background: white;


}
.txtUnderLine a:link{
    color:white;
}
.rmvUnderLine a:link{
    color:green;
}
.colTxt{
    padding: 0px;
    
}
.nav-link {
    display: block;
    padding: 0px;
}
.txtCenter{
    text-align: center;
    position: relative;
    top: 10px;
    color: white;
}





/* Style the tab */
.tab {
    float: left;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 30%;
    min-height: 600px;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    display: block;
   
    color: white;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #099769;
  }
  
  /* Create an active/current "tab button" class */
  .tab button.active {
    background-color: #ccc;
  }
  
  /* Style the tab content */
  .tabcontent {
    float: left;
    padding: 0px 12px;
    border: 1px solid #ccc;
    width: 70%;
    border-left: none;
    height: 600px;
    position: absolute;
    right: 0;
    height: 600px;
    overflow: auto;
  }

  .service-tab{
    height: 600px;
    width: 100%;
    margin-bottom: 25px;
    overflow: hidden !important;
    position: relative;
  }

  .leftMenu{
    overflow: auto;
    background: mediumseagreen;
  }
  .imgSize{
    height: auto;
    width: 100%;
  }

  .activeTab{
    background-color: #099769;
  }
  

  #ex3::-webkit-scrollbar-thumb{
    background-color:darkseagreen;
    border-radius:10px;
    }
    #ex3::-webkit-scrollbar-thumb:hover{
    background-color:#099769;
    
    }
    #ex3::-webkit-scrollbar-thumb:active{
    background-color:#099769;
    } 

    #ex3::-webkit-scrollbar{
        width:8px;
        background-color:#cccccc;
        } 

.tab_content img{
   clear: right;
   float: left;
   width: 30vmin;
}

.tab_content p{
    line-height: 2;
    text-align: left;
    word-spacing:  1vmin;
}

.titleConfig{
    color:green;
    padding:10px;
}

.contentConfig{
    line-height: 30px;
}