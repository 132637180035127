
.responsiveGrid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: fit-content;
}
.responsiveGrid .tileData {
    width: 300px;
    padding: 20px 10px;
    min-height: 275px;
}


.responsiveGrid .tileData .tile {
    border: 1px solid #ccc;
    transition: 0.5s;
    margin: 10px;
    height: 100%;
}
.responsiveGrid .tileData .tile .title {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding: 15px;
    box-shadow: 0 0 2px 0px #ccc;
    font-family: 'Amaranth', sans-serif;
    letter-spacing: 1.2px;
}
.responsiveGrid .tileData .tile .image {
    padding: 15px;
    height: 190px;
    
}
.responsiveGrid .tileData .tile .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.5s;
}
.responsiveGrid:after {
    content: "";
    flex: auto;
    width: 313px;
    padding: 10px;
}

.buyBtn{
    width: 50%;
    background: linear-gradient( 180deg,rgb(51, 196, 147),mediumseagreen);
    border: none;
}
.price{
    padding:20px;
    color: green;
    font-weight: bold;
}
.inputSpinner .form-control{
    text-align: center;
}
#input-spinner-right-button, #input-spinner-left-button{
    background-color: #2DA76D;
    border: none;
}

.toDateTime{
    margin-top: 30px;
}

.MuiDateTimePickerTabs-tabs {
    background-color: #2DA76D !important;
}

.MuiPickersDay-root.Mui-selected{
    background-color: #2DA76D !important;
}

.MuiClockPointer-thumb{
    border: 16px solid #2DA76D !important;
}

.MuiClock-pin{
    background-color: #2DA76D !important;
}

.MuiPickersYear-yearButton.Mui-selected {
    background-color: #2DA76D !important;
}

.MuiClockPointer-root {
    background-color: #2DA76D !important;
}

.MuiClockPointer-noPoint {
    background-color: #2DA76D !important;
}
.MuiOutlinedInput-input{
    padding-left: 7px !important;
}
.pagination{
    display: block !important;
    text-align: right !important;
}


.blink {
    font-weight: bold;
    animation-duration: 1200ms;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    -webkit-animation:blink 1200ms infinite; /* Safari and Chrome */
}
@keyframes blink {
    from {
        color:white;
    }
    to {
        color:green;
    }
}
@-webkit-keyframes blink {
    from {
        color:white;
    }
    to {
        color:green;
    }
}

.txtColor{
    color:green
}
.anchorTag{
    color:green;
    font-weight: bold;
    text-decoration: none;
}
.txtColor a:hover {
    background-color: white;
    color:green
  }

.requiredColor{
    color:red;
} 
.serviceDropDown select{
    -webkit-appearance: menulist !important;
} 

.anchorTag{
    cursor: pointer;
}