
/* Sticky Side Icons code starts*/

.fl-fl {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff3019+19,cf0404+53 */
background: rgb(255,48,25); /* Old browsers */
background: -moz-linear-gradient(top,  rgb(255,48,25) 19%, rgb(207,4,4) 53%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgb(255,48,25) 19%,rgb(207,4,4) 53%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgb(255,48,25) 19%,rgb(207,4,4) 53%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3019', endColorstr='#cf0404',GradientType=0 ); /* IE6-9 */

  text-transform: uppercase;
  
  width: 210px;
  position: fixed;
  z-index: 1000;
  right:-170px;
  -webkit-transition: all .55s ease;
  -moz-transition: all .55s ease;
  -ms-transition: all .55s ease;
  -o-transition: all .55s ease;
  transition: all .55s ease;
}



.fl-fl:hover {
  right:0px;
  cursor: pointer;
}
.txt{
  font-size: 15px;
  color: white;
}

.ambulanceIcon{
  color: #fff;

  padding-right: 15px;
    padding-left: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.bloodBankIcon{
  color: #fff;

  padding-right: 16px;
    padding-left: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.cartIcon{
  color: white;
  /* height: 10%; */
  background: linear-gradient(to bottom, rgb(45 167 109) 19%,#28a745 53%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3019', endColorstr='#cf0404',GradientType=0 );
  text-transform: uppercase;
  width: 210px;
  position: fixed;
  z-index: 1000;
  right: -170px;
}

.float-fb {
  top: 160px;
}

.float-tw {
  top: 210px;
}

.float-cart{
  top:120px;
}

.mainContent-wrapper{
  max-width: 100%;
  border-top: 3px solid rgba(198,198,198,0.5);
}
.cartPadding{
  padding:10px;
}

td {
  word-break: break-word;
}

.badge-danger {
  border-radius: 18px;
  position: absolute;
    top: -10px;
    left: 10px;
}

.buyProduct .modal-dialog{
}

.inputSpinner-table .form-control{
  text-align: center;
}

.inputSpinner-table .input-group{
}
.formBackground{
    margin: auto;
    border: 1px dashed grey;
    border-radius: 10px;
}

.paddingForm{
  padding:0px 30px 0px 30px;
}

.viewOrderButton{
  background: #37BC83;
    border-radius: 10px;
    height: 50px;
    color: white;
    font-weight: bold;
    padding: 10px;
}
.hiddenFormCls{
  display: none;
}
.showFormCls{
 
}

.imgTable{
  width:10%;
}



body {
  margin: 0;
  font-size: 100%;
  padding: 10px;
  padding: 0;
}

.radiobox__wrapper label:before {
  border-color: #000;
}

.radiobox__wrapper.checked label:before {
  background-color: #000 !important;
}

:global .icon {
  color: #7eb4ef;
}

.my-button {
  font-size: 14px;
}

.my-button__red {
  color: #fff;
  border-radius: 2px;
  background: #ff5a57;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}

.sub-section-title-wrapper {
  padding: 2%;
}

.sub-section-title {
  background: #7eb4ef;
  color: #fff;
  padding: 30px;
  font-size: 32px;
}

.block {
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  padding: 2%;
}

.productTable{
  overflow: hidden;
}
.productTable td{
  border:none;
}
.productTable thead{ 
 background: #37BC83;
 color:white;
}
.productTable th{ 
  border: none;
 }
 .productTable table{ 
  border: none;
 }
 .headerFont{
  text-align: center;
  padding: 10px;
  text-decoration: underline;
 }
.modalTitle .fa-shopping-cart{
  color:green;
  padding-right: 10px;
  font-size: 35px;
}

/*For shopping cart icon*/
div[disabled]  
{
  pointer-events: none;
  opacity: 0.3;
}

.cartButtons{
  width:100%;
}
.txtCenterCart{
  text-align: center;
}

.continueShopping{
  font-size: 22px;
}

.tickIcon{
  position: relative;
    top: -8px;
    right: 3px;
    font-size: 16px;
}


#total {
  text-align:right;
  width:100%;
}

#table {
  
}

#table th, #table td {
  border:none;
}

.my-modal {
  width: 90vw;   
  max-width:90vw;
}


.checkbox {
  position: relative;
  margin-bottom: 20px;
  left: -11px;
    top: 3px;

}

.checkbox label {
  padding-left: 30px;
  display: block;
}

.checkbox input {
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.checkbox input:checked + .input-helper:before {
  border-color: #37BB81;
}

.checkbox .input-helper:before,
.checkbox .input-helper:after {
  position: absolute;
  content: "";
  transition: all 200ms;
}

.checkbox .input-helper:before {
  left: 0;
  border: 2px solid #7a7a7a;
}

.checkbox input {
  width: 15px;
  height: 15px;
}

.checkbox input:checked + .input-helper:before {
  background-color: #37BB81;
}

.checkbox input:checked + .input-helper:after {
  transform: scale(1);
  opacity: 1;
}

.checkbox .input-helper:before {
  top: 0;
  width: 20px;
    height: 20px;
  border-radius: 2px;
  color: #fff;
}

.checkbox .input-helper:after {
  content: '\2713';
  font-size: 13px;
  font-weight: bold;
  left: 2px;
  top: 0px;
  color: #fff;
  transform: scale(0);
  opacity: 0;
}

.info-box{
  
    height: 40px;
}
.input-css{
  border-bottom: 1px solid rgb(158, 149, 149);
  width: 100%;
  line-height: 30px;
}
.viewBtn{
  text-align: right;
}
.requiredField{
  color:red;
}
.errorMessage{
  font-size: 12px;
  color: red;
}
.addressTxt{
  width: 100%;
}


/*Phone call css**/
.Phone {
  position: relative;
  display: block;
  margin: 0;
  width: 1em;
  height: 1em;
  font-size: 50px;
  background-color: green;
  border-radius: 0.5em;
  box-shadow: 0 0 0 0em rgb(52 152 219 / 0%), 0em 0.05em 0.1em rgb(0 0 0 / 20%);
  transform: translate3d(0, 0, 0) scale(1);
}
.Phone::before {
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(0);
}
.Phone.is-animating::before {
  -webkit-animation: phone-inner 3000ms infinite;
  animation: phone-inner 3000ms infinite;
}
.Phone::before, .Phone::after {
  position: absolute;
  content: "";
}
.Phone.is-animating::after {
  -webkit-animation: phone-icon 3000ms infinite;
  animation: phone-icon 3000ms infinite;
}
.Phone::after {
  top: 0.25em;
  left: 0.25em;
  width: 0.5em;
  height: 0.5em;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0);
}
.Phone::before, .Phone::after {
  position: absolute;
  content: "";
}
@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
      0 0 0 0em rgba(green, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
  }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow:
      0 0 0 0em rgba(green, 0.1),
      0em 0.05em 0.1em rgba(#000000, 0.5);
  }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
      0 0 0 0.5em rgba(green, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
      0 0 0 0em rgba(green, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
  }
}

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
}

@keyframes phone-icon {
  0% { transform: translate3d(0em, 0, 0); }
  2% { transform: translate3d(0.01em, 0, 0); }
  4% { transform: translate3d(-0.01em, 0, 0); }
  6% { transform: translate3d(0.01em, 0, 0); }
  8% { transform: translate3d(-0.01em, 0, 0); }
  10% { transform: translate3d(0.01em, 0, 0); }
  12% { transform: translate3d(-0.01em, 0, 0); }
  14% { transform: translate3d(0.01em, 0, 0); }
  16% { transform: translate3d(-0.01em, 0, 0); }
  18% { transform: translate3d(0.01em, 0, 0); }
  20% { transform: translate3d(-0.01em, 0, 0); }
  22% { transform: translate3d(0.01em, 0, 0); }
  24% { transform: translate3d(-0.01em, 0, 0); }
  26% { transform: translate3d(0.01em, 0, 0); }
  28% { transform: translate3d(-0.01em, 0, 0); }
  30% { transform: translate3d(0.01em, 0, 0); }
  32% { transform: translate3d(-0.01em, 0, 0); }
  34% { transform: translate3d(0.01em, 0, 0); }
  36% { transform: translate3d(-0.01em, 0, 0); }
  38% { transform: translate3d(0.01em, 0, 0); }
  40% { transform: translate3d(-0.01em, 0, 0); }
  42% { transform: translate3d(0.01em, 0, 0); }
  44% { transform: translate3d(-0.01em, 0, 0); }
  46% { transform: translate3d(0em, 0, 0); }
}